import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';

/*---Queries---*/
import BlogTemplate from './Blog-template';

const CategoryPage = ({
  data,
  pageContext,
}: {
  data: any;
  pageContext: any;
}) => {
  const { edges } = data.allMarkdownRemark;

  return <BlogTemplate data={edges} pageContext={pageContext} />;
};

CategoryPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default CategoryPage;

export const pageQuery = graphql`
  query categoryPageQuery($skip: Int!, $limit: Int!, $category: String!) {
    allMarkdownRemark(
      filter: {
        frontmatter: {
          templateKey: { eq: "BlogPost-page" }
          category: { eq: $category }
        }
      }
      sort: { fields: [frontmatter___date], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          frontmatter {
            title
            author
            date(formatString: "DD MMMM YYYY", locale: "de-DE")
            category
          }
          fields {
            slug
          }
          excerpt(pruneLength: 350)
        }
      }
    }
  }
`;
